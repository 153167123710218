<template>
<div class="login-view">
    <section v-if="!$route.params.step" class=" login-container">
        <form class="login-form flex-column clamped" novalidate v-on:submit.prevent="onSubmit">
            <h1 class="form-header uppercase">{{ $t('FORM.SIGNUP_SIGNIN') }}</h1>
            <p v-if="isStreaming" class="paragraph tv-content">
                {{ $t('TV.LOGIN_MSG') }}
            </p>
            <ButtonElement
                role="button" type="button" class="outline wide login-btn google"
                v-on:click="authenticateGoogleUser"
                v-on:keydown.enter="authenticateGoogleUser"
            >
                <NuxtImg class="google-icon" src="/images/icons/google-icon-color.svg" alt="Google" />
                {{ $t('FORM.CONTINUE_WITH_GOOGLE') }}
            </ButtonElement>
            <div class="joining-text uppercase">{{ $t('FORM.OR') }}</div>
            <EmailInput
                v-model="email.value"
                :label="$t('FORM.EMAIL_ADDRESS')"
                v-on:keyup="startTypingTimer"
                v-on:blur="validateEmail"
                v-on:keydown.enter="checkIfUserExists"
            />
            <ButtonElement
                role="button" type="submit" class="primary wide login-btn continue"
                :disabled="!canSubmit" v-on:click="checkIfUserExists" v-on:keydown.enter="checkIfUserExists"
            >
                {{ $t('FORM.CONTINUE') }}
            </ButtonElement>
            <div class="disclaimer-text">
                {{ $t('FORM.DISCLAIMER_TEXT') }}
                <NuxtLink class="paragraph-s link" :to="localeRoute({ name: 'terms-of-service' })">
                    {{ $t('FOOTER.TERMS_OF_SERVICE') }}
                </NuxtLink>
                {{ $t('FORM.AND') }}
                <NuxtLink class="paragraph-s link" :to="localeRoute({ name: 'privacy-policy' })">
                    {{ $t('FOOTER.PRIVACY_POLICY') }}
                </NuxtLink>
            </div>
        </form>
    </section>
    <section v-if="$route.params.step === 'create-user-profile'" class="login-container flex-column">
        <CreateUserProfileForm :email="currentUser.state.email" />
    </section>
    <section v-if="$route.params.step === 'email-signin' && currentUser.state.email" class="login-container flex-column">
        <ExistingUserPasswordForm :email="currentUser.state.email" />
    </section>
    <section v-if="$route.params.step === 'unauthorized'" class="login-container flex-column">
        <Unauthorized />
    </section>
</div>
</template>

<script lang="ts">
import type { JBGWeb } from '$types/JBGWeb'
import { JBGApi } from '$services/api'
import { User, useCurrentUser } from '$services/user'

import EmailInput from '$components/input/fields/EmailInput.vue'
import ButtonElement from '$components/ButtonElement.vue'
import CreateUserProfileForm from '$components/forms/CreateUserProfileForm.vue'
import ExistingUserPasswordForm from '$components/forms/ExistingUserPasswordForm.vue'
import FormErrorToolTip from '$components/forms/FormErrorToolTip.vue'
import Unauthorized from '$components/Unauthorized.vue'

export default defineNuxtComponent({
    components: {
        ButtonElement,
        EmailInput,
        CreateUserProfileForm,
        Unauthorized,
        FormErrorToolTip,
        ExistingUserPasswordForm
    },

    data() {
        return {
            email: { value: '', invalid: false } as JBGWeb.UserFormFieldData,
            typingTimer: <ReturnType<typeof setTimeout> | null> null,
            currentUser: useCurrentUser(),
            isStreaming: false
        }
    },

    computed: {
        canSubmit() {
            if (this.email.invalid) return false
            return true
        }
    },

    mounted() {
        if (this.$route.params.step === 'email-signin' && !this.currentUser.state.email) {
            void this.$router.push({ path: '/login' })
        }
        if ('streaming' in this.$route.query) {
            this.isStreaming = true
        }
    },
    methods: {
        async checkIfUserExists() {
            if (!this.canSubmit) return

            try {
                // TODO: remove this when we leave closed beta
                // check if email contains jbg domain
                if (!this.email.value.includes('@jackboxgames.com')) {
                    throw new JBGApi.Error('unauthorized', 401, 401)
                }

                this.currentUser.state.email = this.email.value
                const res = await this.$user.getUserByEmail(this.email.value)
                // if user exists in the db, route to pwd input screen
                if (res.status === 202) {
                    this.navigateToLogin('email-signin')
                }
            } catch (err) {
                if (err && err instanceof JBGApi.Error) {
                    // if user doesn't exist in the db,
                    // route to create user profile screen
                    if (err.httpStatus === 404) {
                        this.navigateToLogin('create-user-profile')
                    }
                    // if unauthorized, route to not on allowlist screen
                    if (err.httpStatus === 401) {
                        this.navigateToLogin('unauthorized')
                    }
                } else {
                    console.error('error occurred checking if user exists: ', err)
                    this.$toast.add({
                        id: 'userexists',
                        type: 'error',
                        text: this.$t('GENERAL.ERROR')
                    })
                }
            }
        },

        authenticateGoogleUser() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.replace(this.localeRoute({
                name: 'token'
            }))
            void this.$user.login()
        },

        onSubmit() {
            return false
        },

        clearTypingTimer(timer: ReturnType<typeof setTimeout> | null) {
            if (!timer) return
            clearTimeout(timer)
            // clear out input error too
            this.email.invalid = false
        },

        startTypingTimer() {
            this.clearTypingTimer(this.typingTimer)
            const DONE_TYPING_INTERVAL = 1500
            this.typingTimer = setTimeout(this.validateEmail, DONE_TYPING_INTERVAL)
        },

        validateEmail() {
            // we're not actually using the invalidationReason on this view b/c the error message is the same for both,
            // but I'm including it anyways to keep the logic consistent across user login views/components.
            if (this.email.value.length === 0) {
                this.email.invalid = true
                this.email.invalidationReason = 'emailBlank'
            } else {
                // eslint-disable-next-line max-len
                const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                const validEmail = emailRegex.test(this.email.value)
                if (!validEmail) {
                    this.email.invalid = true
                    this.email.invalidationReason = 'emailPattern'
                }
            }
        },

        navigateToLogin(step?: string) {
            if (!step) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return void this.$router.push(this.localeRoute({
                    path: '/login'
                }))
            }

            if (step === 'create-user-profile') {
                this.currentUser.setStatus(User.Status.CreatingByEmail)
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            void this.$router.push(this.localeRoute({
                name: 'login-step',
                params: {
                    step
                }
            }))
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;
.login-container {
    align-items: center;
    background: var(--surface-900);
    min-height: 100vh;
    z-index: 2;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    @include mq-small-and-less {
        padding: 0px 16px;
    }

    .login-form {
        align-items: center;
        max-width: 440px;
        padding-top: 64px;
        text-align: center;
        width: 100%;

        @include mq-xsmall {
            padding-top: 32px;
        }

        h1 {
            font-size: 32px;
            font-weight: 800;
            line-height: 32px;
            margin: 0;
            padding-bottom: 24px;
            &.uppercase {
                text-transform: uppercase;
            }

            @include mq-xsmall {
                font-size: 26px;
                line-height: 26px;
            }
        }

        .tv-content {
            margin-bottom: 32px;
            text-align: left
        }

        .input {
            font-size: 18px;
            height: 48px;
            margin-top: 8px;
            padding: 16px;

            &:active, &:focus {
                outline: none;
            }

            &.invalid {
                border-bottom: 2px solid var(--red-300);
            }
        }

        .joining-text {
            font-size: 28px;
            font-weight: 900;
            line-height: 28px;
            letter-spacing: -0.56px;
            margin-bottom: 24px;

            &.uppercase {
                text-transform: uppercase;
            }

            @include mq-xsmall {
                font-size: 22px;
                font-weight: 800;
                line-height: 22px;
                letter-spacing: -0.44px;
            }
        }

        .label {
            align-self: start;
            color: var(--neutral-50);
            font-size: 15px;
            font-weight: 700;
            line-height: 15px;
            text-align: left;
            text-transform: uppercase;

            @include mq-xsmall {
                font-size: 14px;
                font-weight: 600;
                line-height: 14px;
            }
        }

        .disclaimer-text {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            width: 330px;

            &.link {
                font-weight: 500;

                @include mq-xsmall {
                    font-size: 14px;
                }
            }
        }

        .disclaimer-text {
            @include mq-xsmall {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                padding: 0px 16px;
                width: 100%;
            }

        }

        .login-btn {
            align-items: center;
            display: flex;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            height: 48px;
            justify-content: center;
            line-height: 24px;

            &.google {
                margin-bottom: 24px;
            }

            &.continue {
                margin: 16px 0px;
            }

            .google-icon {
                margin-right: 16px;
                height: 20px;
                width: 20px;
            }
        }
    }
}
</style>
